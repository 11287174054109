import React from "react";
import { Skeleton } from "../../Skeleton/Skeleton";

const Vertical = ({animation = true}) => (
  <div className="cui-flex cui-w-auto cui-py-xs cui-px-md cui-justify-between">
    <div className="cui-flex cui-flex-col cui-w-1/2 cui-gap-xs">
      <div className="cui-flex cui-items-center cui-gap-sm">
        <Skeleton width={24} height={24} rounded="full" animation={animation} />
        <Skeleton width={60} height={24} animation={animation} />
      </div>
      <div className="cui-flex cui-items-center cui-gap-sm">
        <Skeleton width={24} height={24} rounded="full" animation={animation} />
        <Skeleton width={60} height={24} animation={animation} />
      </div>
    </div>
    <div className="cui-flex cui-gap-sm">
      <div className="cui-flex-col cui-flex cui-items-end cui-justify-center cui-gap-sm">
        <Skeleton width={96} height={24} animation={animation} />
      </div>
      <div className="cui-flex-col cui-flex cui-items-center cui-justify-center cui-gap-sm">
        <Skeleton width={96} height={48} animation={animation} />
      </div>
    </div>
  </div>
);

const Horizontal = ({animation = true}) => (
  <div className="cui-flex cui-w-auto cui-py-xs cui-px-md">
    <div className="cui-flex cui-items-center cui-gap-sm cui-w-1/3">
      <Skeleton width={48} height={48} rounded="full" animation={animation} />
      <Skeleton width={48} height={24} animation={animation} />
    </div>
    <div className="cui-flex-col cui-flex cui-items-center cui-justify-center cui-gap-sm cui-flex-grow">
      <Skeleton width={96} height={24} animation={animation} />
      <Skeleton width={80} height={24} animation={animation} />
    </div>
    <div className="cui-flex cui-items-center cui-justify-end cui-gap-sm cui-w-1/3">
      <Skeleton width={48} height={24} animation={animation} />
      <Skeleton width={48} height={48} rounded="full" animation={animation} />
    </div>
  </div>
);

export const BoxScoreSkeleton = ({
  orientation = "horizontal",
  animation = true
}: {
  orientation: "horizontal" | "vertical";
  animation: boolean;
}) => {
  return {
    vertical: <Vertical animation={animation} />,
    horizontal: <Horizontal animation={animation} />,
  }[orientation];
};
