import { useMemo } from "react";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";
import get from "lodash-es/get";

dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

export const makeAriaLabel = ({
  date,
  _competitorOne,
  _competitorTwo,
  firstCompetitor,
  secondCompetitor,
  hasLiveStream,
  detailsProps,
  gameState,
}) => {
  const ariaDate = useMemo(() => {
    let ariaDate;
    if (date && dayjs(date).isSameOrAfter(dayjs(Date.now()))) {
      const timestamp: any =
        date && dayjs.duration(dayjs(Date.now()).diff(date));
      const days =
        Math.abs(timestamp.months()) * 30 + Math.abs(timestamp.days());
      const dayStr =
        days > 0
          ? `starts in ${days} ${days > 1 ? "days" : "day"}`
          : "starts in";
      ariaDate = `${dayStr} ${Math.abs(timestamp.hours())} hours, ${Math.abs(
        timestamp.minutes()
      )} minutes, ${Math.abs(timestamp.seconds())} seconds`;
    } else {
      ariaDate = `event started`;
    }
    return ariaDate;
  }, [date]);

  const ariaLabelPreGame = useMemo(() => {
    return `Link, All bets for ${firstCompetitor ?? "Unknown"} at ${
      secondCompetitor ?? "Unknown"
    }, ${dayjs(date).format("ddd h:mm:A")}, ${ariaDate}, ${
      hasLiveStream ? "livestream available" : ""
    }`;
  }, [
    firstCompetitor,
    secondCompetitor,
    _competitorOne,
    _competitorTwo,
    ariaDate,
    detailsProps,
    hasLiveStream,
    gameState,
  ]);
  // Link to event page. Live scoreboard showing [Left team] vs [Right team]. Current score: [Left team] [Score], [Right team][Score]. Possession: [Team with possession.] [DateDetails]."
  const teamWithPossession = _competitorOne?.possession
    ? firstCompetitor
    : secondCompetitor;
  const ariaLabel = useMemo(() => {
    return `Link to event page. Live scoreboard showing ${
      firstCompetitor ?? "Unknown"
    } vs ${secondCompetitor ?? "Unknown"}.  Current score: ${
      firstCompetitor ?? "Unknown"
    } ${_competitorOne?.score ?? 0}, ${secondCompetitor ?? "Unknown"} ${
      _competitorTwo?.score ?? 0
    }.  Possession: ${teamWithPossession}. ${
      detailsProps?.eventDetails?.gameState
    }`;
  }, [
    firstCompetitor,
    secondCompetitor,
    _competitorOne,
    _competitorTwo,
    ariaDate,
    detailsProps,
    hasLiveStream,
    gameState,
  ]);
  return { ariaLabelPreGame, ariaLabel };
};
