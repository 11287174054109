import React, { useMemo } from "react";
import { Details } from "../../../../Details/Details";
import { Icon } from "../../../../Icon/Icon";
import cx from "components/src/utils/cx";
import {
  SemanticColorType,
  tailwindSemanticBgColors,
} from "components/src/shared/styles/colorStyles";
import { SizeType } from "../../../../../shared/types";
import { DetailProps } from "../../../../Details/types";
import { IconProps } from "../../../../Icon/types";
import { TempChevron } from "../../../templates/components/AlternateHeader";

export const MarketFixtureHeader = ({
  title,
  id,
  description,
  leadingIcon,
  trailingDetails = [],
  collapsible,
  headerBg = "bg-primary-minimal",
  actionText,
  hideActionText,
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  id?: string;
  title?: string;
  leadingIcon?: IconProps | string;
  trailingDetails?: DetailProps[] | null;
  collapsible?: boolean;
  headerBg?: string;
  description?: string;
  hideActionText?: boolean;
  actionText?: string;
  onClick?: (args: {
    e?: React.MouseEvent<HTMLDivElement>;
    id?: string;
  }) => void | null;
}) => {
  let _LeadingIcon;
  if (typeof leadingIcon === "string")
    _LeadingIcon = <Icon name={leadingIcon} size="lg" />;
  if (typeof leadingIcon === "object") _LeadingIcon = <Icon {...leadingIcon} />;

  const rightList = useMemo(() => {
    const moreTag: DetailProps = {
      tag: actionText ? actionText : "More",
      rightIcon: {
        name: "ui_chevron-right",
        size: "md" as SizeType,
        color: "fg-primary",
      },
      fgColor: "fg-primary" as SemanticColorType,
    };

    if (trailingDetails && Array.isArray(trailingDetails))
      return [...trailingDetails, collapsible ? null : moreTag];
    return !collapsible && !hideActionText ? [moreTag] : [];
  }, [title, leadingIcon, collapsible, trailingDetails]);

  const handleOnClick = (e) => {
    onClick && onClick(e);
  };

  return (
    <div
      className={cx(
        {
          [tailwindSemanticBgColors[headerBg]]: true,
          "cui-items-center": !!!description,
          "cui-items-start": !!description,
        },
        "cui-flex cui-justify-between cui-px-md cui-py-sm cui-cursor-pointer cui-min-h-[32px]"
      )}
      style={{ background: headerBg }}
      onClick={handleOnClick}
    >
      <div className="cui-flex-col">
        <div className="cui-flex cui-items-start cui-flex-shrink">
          {_LeadingIcon ? <div className="cui-h-2">{_LeadingIcon}</div> : null}
          <div
            className={cx(
              {
                "cui-ml-xs": !!_LeadingIcon,
              },
              "heading-lg-bold cui-text-fg-default cui-self-center"
            )}
          >
            {title}
          </div>
        </div>
        {!!description ? (
          <span className="heading-xs-bold cui-text-fg-default">
            {description}
          </span>
        ) : null}
      </div>
      <TrailingDetails
        collapsible={collapsible}
        list={rightList}
        isOpen={isOpen}
      />
    </div>
  );
};

const TrailingDetails = ({ collapsible, isOpen, list }) => {
  return (
    <div className="cui-flex cui-items-center cui-justify-end cui-gap-xs cui-w-auto cui-ml-xs cui-min-w-[64px]">
      <Details list={list} />
      {collapsible ? (
        <TempChevron
          className={cx({
            "cui-rotate-180": !isOpen,
          })}
        />
      ) : null}
    </div>
  );
};
