import React, { useMemo } from "react";
import type { MarketTemplateEventDetailsType } from "../../../types";
import { Details } from "components/src/components/Details/Details";
import { type DetailsProps } from "components/src/components/Details/types";
import { prepareEventDetails } from "components/src/components/MarketTemplate/js/prepareEventDetails";
import { useSync } from "components/src/shared/hooks/useSync";
import type { BoxScoreSyncMapperType } from "components/src/components/BoxScore/types";

export const EventDetails = (
  props: MarketTemplateEventDetailsType & Partial<DetailsProps> & { syncMapper?: BoxScoreSyncMapperType},
) => {
  
  const _props = useMemo(()=>{
    let _props = props;  
    if (props?.syncMapper && "sync" in props && props.sync) {
      _props = {...props, sync: {...props.sync, mapper: props?.syncMapper}};
    };
    return _props;
  },[props])


  const propsWithSync = useSync<MarketTemplateEventDetailsType>(_props);
  const _eventDetails: any = useMemo(() => {
    return prepareEventDetails(propsWithSync);
  }, [
    propsWithSync,
    propsWithSync?.home?.score,
    propsWithSync?.away?.score,
    propsWithSync?.home?.possession,
    propsWithSync?.away?.possession,
    propsWithSync?.gameState,
    propsWithSync?.isLive,
    propsWithSync?.isRainDelayed,
    propsWithSync?.isSuspended,
    propsWithSync?.gameTime,
    propsWithSync?.competitor1?.score,
    propsWithSync?.competitor2?.score,
    propsWithSync?.competitor1?.possession,
    propsWithSync?.competitor2?.possession,
    propsWithSync?.sport,
  ]);

  const { detailsList } = _eventDetails;
  return detailsList?.length ? (
    <Details
      list={detailsList}
      dividerColor={props?.dividerColor}
      noMinHeight={props?.noMinHeight}
      noPad={props?.noPad}
      textSize={props?.textSize}
    />
  ) : null;
};
