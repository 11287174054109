import React, { useMemo } from "react";
import { StatusMessageProps } from "./types";
import cx from "components/src/utils/cx";
import { useIconComponents } from "components/src/shared/hooks/useIconComponents";
import { cssColor } from "components/src/shared/utils/cssUtils";
import scssStyles from "./scss/StatusMessage.module.scss";

const statusMap = {
  primary: {
    icon: { name: "", color: "fg-primary" },
    color: "cui-text-fg-primary",
  },
  alert: {
    icon: { name: "ui_warning", color: "status-alert" },
    color: "cui-text-status-alert-strong",
  },
  positive: {
    icon: { name: "ui_checkmark-circle", color: "status-positive" },
    color: "cui-text-status-positive",
  },
  negative: {
    icon: { name: "ui_error", color: "status-negative" },
    color: "cui-text-status-negative",
  },
  info: {
    icon: { name: "ui_info", color: "status-informational" },
    color: "cui-text-status-informational",
  },
  disabled: {
    icon: { name: "ui_ban", color: "fg-moderate" },
    color: "cui-text-fg-moderate",
  },
};

const statusMessageSizeMap = {
  md: "sm",
  lg: "md",
  xl: "lg",
};

export const StatusMessage = ({
  textSize,
  text = "",
  status = null,
  icon,
  fgColor,
  size = "lg",
  children,
  ...rest
}: StatusMessageProps) => {
  let _icon = icon;
  const hasStatus = status && status in statusMap;
  let currentStatus = { color: "" };
  const _textSize = textSize
    ? textSize
    : `body-${statusMessageSizeMap[size]}-bold`;
  if (hasStatus) {
    currentStatus = statusMap[status];
    _icon = statusMap[status].icon;
  }

  const statusIcon = useIconComponents(_icon, "Icon", {
    size: "xs",
    className: "cui-mr-1",
  });

  const styles = useMemo(() => {
    let _styles = {};
    if (fgColor) {
      _styles = {
        color: cssColor(fgColor),
      };
    }
    return _styles;
  }, [fgColor, textSize, size]);
  return (
    <span
      className={cx(
        {
          [currentStatus.color]: !!hasStatus,
        },
        `cui__status-message ${_textSize} cui-flex cui-items-center ${scssStyles["cui__status-message"]} ${scssStyles["cui__fadein"]}`
      )}
      style={styles}
      {...rest}
    >
      {statusIcon}
      {children || text}
    </span>
  );
};
