import React from "react";
import { Icon } from "../../Icon/Icon";
import { SizeType } from "src/shared/types";
import cx from "components/src/utils/cx";

type LegBadgeProps = { name?: string, size?: SizeType }
export const LegBadge = ({ name, size = "sm"}: LegBadgeProps) => (
  <div className={cx({
    "cui-p-2px": size === "2xs",
    "cui-px-2xs cui-py-2px": size !== "2xs"
  },"cui-bg-bg-primary cui-flex cui-items-center cui-justify-center cui-rounded-1")}>
    <Icon name={name} color="fg-inverse" size={size} />
  </div>
);
