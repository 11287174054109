import { useMemo } from "react";
import { CompetitorWrapper } from "./CompetitorWrapper";
import { CompetitorName } from "./CompetitorName";
import { ScoreWithPossesion } from "./ScoreWithPossesion";
import { CompetitorDescription } from "./CompetitorDescription";
import cx from "components/src/utils/cx";

export const CompetitorHorizontal = ({
    wrapperProps,
    showComputedIcon,
    activeIcon,
    inverse,
    readableToken,
    hasForceReadability,
    competitorNameProps,
    scoreWithPossesionProps,
    description,
    variant,
    center,
    compact,
    hasContainerQueries,
    inline,
    hideScore,
    hasScorePadding,
    hideScorePadding, 
    setCompetitorRef
  })=>{

    const { hasScore } = scoreWithPossesionProps;
    const computedClasses = useMemo(() => {
      if (inline) {
        return cx({
          "cui-flex-row-reverse": inverse
        },"cui-inline-flex cui-items-center");
      }
      return cx({
        "cui-@container": hasContainerQueries,
        "cui-flex-row cui-w-[calc(100%-16px)] cui-items-center": !center,
        "cui-w-full cui-justify-center cui-items-center": center,
        "cui-flex cui-flex-row-reverse": inverse,
        "cui-flex": !inverse,
      })
    },[hasContainerQueries, inverse, center, inline]);
    const showPadding = (hasScore || hasScorePadding) && !hideScorePadding;
    return (
        <CompetitorWrapper {...wrapperProps}>
          <div
            className={cx(
              {
                "cui-justify-center": center,
                "cui-justify-end": inverse,
              },
              "cui-w-full cui-flex cui-flex-grow cui-items-center cui-relative"
            )}
            ref={setCompetitorRef}
          >
            <div
              className={computedClasses}
            >
              {showComputedIcon ? (
                <div
                  className={cx(
                    {
                      "cui-pr-xs": !inverse,
                      "cui-pl-xs": inverse
                    },
                    "cui-flex cui-min-w-[24px]"
                  )}
                >
                  {activeIcon}
                </div>
              ) : null}
              <div className={cx({
                [readableToken as string]: hasForceReadability, 
                "cui-text-fg-default": !hasForceReadability,
                "cui-pl-md": inverse && showPadding,
                "cui-pr-md": !inverse && showPadding
              },"cui-flex cui-flex-col")}>
                <CompetitorName {...competitorNameProps} forcedReadableToken={readableToken} />
                <CompetitorDescription
                  description={description}
                  inverse={inverse}
                  variant={variant}
                  forcedReadableToken={readableToken}
                />
              </div>
              {compact && hasScore && !hideScore ? <ScoreWithPossesion {...scoreWithPossesionProps} forcedReadableToken={readableToken} /> : null}
            </div>
          </div>
          {!compact && hasScore && !hideScore ? <ScoreWithPossesion {...scoreWithPossesionProps} forcedReadableToken={readableToken} /> : null}
        </CompetitorWrapper>
      )
}