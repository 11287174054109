import React, { useEffect, useMemo, useState, useRef, useCallback } from "react";
import { BoxScoreProps, BoxScoreDateType, BoxScoreVariantType } from "./types";
import { Competitor } from "./../Competitor/Competitor";
import { BoxScoreDetails as Details } from "./components/BoxScoreDetails";
import { BoxScoreSkeleton } from "./components/BoxScoreSkeleton";
import cx from "components/src/utils/cx";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import duration from "dayjs/plugin/duration";
import get from "lodash-es/get";
import { useTeamColors } from "components/src/shared/hooks/useTeamColors";
import { useSize } from "components/src/shared/hooks/useSize";
import { mapperFactory as eventDetailsToCompetitorSyncMapper } from "../MarketTemplate/js/prepareMarkets/mappers/mapCompetitorSync";
import { makeAriaLabel } from "./js/makeAriaLabel";
import { LiveStreamIcon } from "./components/LiveStreamIcon";
import { randomIdString } from "components/src/shared/utils/randomIdString";
import { kebabCase } from "components/src/shared/utils/kebabCase";

dayjs.extend(isSameOrAfter);
dayjs.extend(duration);

export const genericCompetitor = { displayName: "--" };

const breakpoints = [
  // {
  //   minSize: 632,
  //   maxSize: Infinity,
  //   name: "shortname",
  //   textSize: "heading-md",
  //   breakpoint: "large",
  // },
  {
    minSize: 451,
    maxSize: Infinity,
    name: "abbreviation",
    textSize: "display-md",
    iconSize: 58,
    scoreSize: "md",
    breakpoint: "small",
  },
  {
    minSize: 376,
    maxSize: 450,
    name: "abbreviation",
    iconSize: "3xl",
    textSize: "display-md",
    scoreSize: "md",
    breakpoint: "mobile",
  },
  {
    minSize: 0,
    maxSize: 375,
    name: "abbreviation",
    iconSize: "2xl",
    textSize: "display-md",
    scoreSize: "md",
    breakpoint: "xs-mobile",
  },
] as const;

const ColorBar = ({
  flip,
  color,
  show,
}: {
  color?: string;
  flip?: boolean;
  show?: boolean;
}) => {
  if (!show) return null;
  return (
    <div
      className={cx(
        {
          "cui-left-0": !!!flip,
          "cui-right-0": !!flip,
        },
        "cui-absolute cui-top-0 cui-w-2 cui-h-full"
      )}
      style={{ background: color ? `${color}` : "var(--bg-layer-three)" }}
    />
  );
};

export const BoxScore = ({
  id = "",
  competitors,
  gameState,
  date,
  hasLiveStream = false,
  onClick,
  showCountdown = true,
  variant = "horizontal",
  skeleton,
  animation = true,
  sport,
  eventDetails,
  syncMapper,
  ...rest
}: BoxScoreProps) => {

  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const { width } = useSize(ref, 10, false, { trailing: true, freeze: 200 }) ?? {};

  /**
   * Computed Props
   */

  const isPreGame = gameState === "pregame";
  const _showCountdown = showCountdown && !!date && isPreGame;
  const detailsProps = useMemo(
    () => ({
      date,
      hasLiveStream,
      variant,
      showCountdown: _showCountdown,
      gameState,
      eventDetails,
      syncMapper
    }),
    [date, hasLiveStream, variant, _showCountdown, gameState, eventDetails]
  );

  const { _competitorOne, _competitorTwo } = useMemo(() => {
    let _competitorOne = competitors
      ? get(competitors, 0, genericCompetitor)
      : genericCompetitor;
    let _competitorTwo = competitors
      ? get(competitors, 1, genericCompetitor)
      : genericCompetitor;
    const isSoccer = sport === "soccer";
    // Setup sync if it exists
    if (eventDetails && "sync" in eventDetails) {
      const syncHome = {
        subscribe: eventDetails?.sync?.subscribe,
        getSnapshot: eventDetails?.sync?.getSnapshot,
        mapper: eventDetailsToCompetitorSyncMapper(isSoccer ? "home" : "away"),
      };
      const syncAway = {
        subscribe: eventDetails?.sync?.subscribe,
        getSnapshot: eventDetails?.sync?.getSnapshot,
        mapper: eventDetailsToCompetitorSyncMapper(isSoccer ? "away" : "home"),
      };
      _competitorOne = { ..._competitorOne, sync: syncHome };
      _competitorTwo = { ..._competitorTwo, sync: syncAway };
    }
    return { _competitorOne, _competitorTwo };
  }, [competitors, eventDetails, eventDetails?.sync]);

  const firstCompetitor = _competitorOne?.team ?? _competitorOne?.displayName;
  const secondCompetitor = _competitorTwo?.team ?? _competitorTwo?.displayName;
  const teams = {
    first: useTeamColors(firstCompetitor),
    second: useTeamColors(secondCompetitor),
  };

  /**
   * Computed Id
   */
  const computedId = useMemo(() => `cui__box_score_${randomIdString()}-${kebabCase(firstCompetitor)}-${kebabCase(secondCompetitor)}`, [firstCompetitor, secondCompetitor]);

  const _id = id ? id : computedId;


  /**
   * Breakpoints
   */

  const {
    name: _name,
    textSize: _textSize,
    iconSize,
    scoreSize,
    breakpoint,
  } = useMemo<{ name: string; textSize: string; breakpoint: string, iconSize: string | number, scoreSize: string }>(() => {
    const breakpointFilter = ({ minSize, maxSize }) => {
      return typeof width === "number" && width >= minSize && width <= maxSize;
    };
    const currentBreakpoint =
      breakpoints.filter(breakpointFilter).at(0) ?? breakpoints.at(0)!; 
    return currentBreakpoint;
  }, [width]);

  const showColorBars = useMemo(()=>{
     return breakpoint !== "mobile" && breakpoint !== "xs-mobile"
  },[breakpoint]);

  // const useCityName =
  //   (teams?.first?.league === "college" ||
  //     teams?.second?.league === "college") &&
  //   breakpoint !== "mobile" && breakpoint !== "xs-mobile";

  const { ariaLabelPreGame, ariaLabel} = makeAriaLabel({
    date,
    _competitorOne,
    _competitorTwo,
    firstCompetitor,
    secondCompetitor,
    hasLiveStream,
    detailsProps,
    gameState
  });

  const [isHorizontal, isVertical] = useMemo(()=>{
    return [variant === "horizontal", variant === "vertical"]
  },[variant]);

  if (skeleton)
    return <BoxScoreSkeleton orientation={variant} animation={animation} />;
  
  return (
    <div
      className={cx(
        {
          "cui-px-md cui-flex cui-items-center cui-justify-center cui-w-auto":
            isVertical && isPreGame,
          "cui-flex cui-flex-col cui-px-md": isVertical && !isPreGame,
          "cui-px-md": isHorizontal && breakpoint !== "xs-mobile",
          "cui-px-2xs": isHorizontal && breakpoint === "xs-mobile",
          "cui-gap-x-md": isHorizontal && breakpoint === "xs-mobile" && isPreGame,
          "cui-grid cui-grid-cols-[1fr,auto,1fr] cui-grid-rows-[1fr,auto] cui-justify-items-center cui-place-items-center":
            isHorizontal
        },
        "cui__boxscore cui-py-xs cui-border-1 cui-border-solid cui-border-fg-minimal cui-bg-bg-layer-one cui-select-none cui-outline-none focus:cui-outline-none focus-visible:cui-ring-2 focus-visible:cui-ring-offset-1 focus-visible:cui-ring-fg-focus cui-relative"
      )}
      aria-label={isPreGame ? ariaLabelPreGame : ariaLabel}
      onClick={(e) => {
        onClick && onClick(competitors, e, _id);
      }}
      tabIndex={0}
      id={_id}
      {...rest}
      ref={setRef}
    >
      {variant === "vertical" ? (<>
        <div className="cui-w-full cui-flex">
          <div className="cui-flex cui-flex-col cui-w-full cui-justify-center cui-max-w-xs">
            <Competitor
              {..._competitorOne}
              iconSize="lg"
              tabbable={false}
              textSize="display-sm"
              sport={sport}
              hideScore={isPreGame}
              animation={animation}
            />
            <Competitor
              {..._competitorTwo}
              iconSize="lg"
              tabbable={false}
              textSize="display-sm"
              sport={sport}
              hideScore={isPreGame}
              animation={animation}
            />
          </div>
          <div className="cui-flex-grow"/>
          <Details {...detailsProps} />
        </div>
        {hasLiveStream && !isPreGame ? <LiveStreamIcon className="cui-w-auto cui-self-end cui-mt-1" /> : null}
        </>
      ) : (
        <>
          <ColorBar
            color={teams?.first?.colors?.at(0)}
            show={showColorBars}
          />
          <div className="cui-justify-self-end cui-flex">
            <Competitor
              {..._competitorOne}
              compact
              inline
              force={_name}
              iconSize={iconSize}
              textSize={_textSize}
              tabbable={false}
              scoreSize={scoreSize}
              sport={sport}
              hideScore={isPreGame}
              hasScorePadding
              flipIcon={true}
              hideScorePadding={breakpoint === "xs-mobile"}
              animation={animation}
              textAlign="right"
              key={`cui-boxscore-competitor-1-${_id}-${breakpoint}`}
            />
          </div>
          <Details {...detailsProps} />
          <div className="cui-justify-self-start cui-flex">
            <Competitor
              {..._competitorTwo}
              compact
              inverse
              inline
              force={_name}
              iconSize={iconSize}
              textSize={_textSize}
              tabbable={false}
              scoreSize={scoreSize}
              hideScore={isPreGame}
              sport={sport}
              hasScorePadding
              hideScorePadding={breakpoint === "xs-mobile"}
              animation={animation}
              key={`cui-boxscore-competitor-2-${_id}-${breakpoint}`}
            />
          </div>
          <ColorBar
            color={teams?.second?.colors?.at(0)}
            flip
            show={showColorBars}
          />
          <div className="cui-col-span-3">
            {hasLiveStream ? <LiveStreamIcon className="" /> : null}
          </div>
        </>
      )}
    </div>
  );
};

BoxScore.displayName = "BoxScore";
