import React from "react";
import { BoxScoreDateType } from "../types";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { Display } from "../../Typography/Typography";

dayjs.extend(isSameOrAfter);

export const StartsIn = ({date, className, hide}: { date: BoxScoreDateType, className?: string; hide?: boolean; }) => { 
    const isAfter = dayjs(date).isSameOrAfter(Date.now());
    if (!isAfter || hide) return null;
    return  <Display size="xs" color="fg-moderate" className={`cui-whitespace-nowrap cui-mt-2px ${className}`}>
    Starts In
    </Display>
}