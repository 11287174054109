import { useState, useLayoutEffect, useRef } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import debounce from "lodash/debounce";

type DebounceSettings = {
  leading?: boolean;
  trailing?: boolean;
  maxWait?: number;
  freeze?: number;
}

export function useSize<T extends HTMLElement>(
  target: T | null,
  debounceDelay: number = 250,
  disable?: boolean, 
  options?: DebounceSettings
) {

  const [size, setSize] = useState<DOMRect>();
  const sizeHistory = useRef<{ width: number | null, height: number | null } | null>(null);
  const timerRef = useRef<number | null>(null);
  const activeRef = useRef(true);

  const debounced = debounce(({ contentRect }) => {
    setSize(contentRect);
  }, debounceDelay, options);

  // Initialize
  useLayoutEffect(() => {
    if (target) {
      setSize(target.getBoundingClientRect());
    }
    return () => {
      debounced.cancel();
    };
  }, [target]);
  
  useLayoutEffect(()=>{
    if (!timerRef.current && size && options?.freeze) {
      activeRef.current = false;
      timerRef.current = setTimeout(()=>{
        activeRef.current = true;
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = null;
      }, options?.freeze ?? 0);
    } 
  }, [size]);

  // Setup RO
  useResizeObserver(target, debounced);

  const sizes =  { width: size?.width || null, height: size?.height || null };
  if (disable || !activeRef.current) {
    debounced.cancel();
    return sizeHistory.current ? sizeHistory.current : null
  };

  sizeHistory.current = sizes;
  return sizes;
}
