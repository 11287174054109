import React from "react";
import { Icon } from "../../Icon/Icon";
import { Avatar } from "../../Avatar/Avatar";
import { Loading } from "../../Loading/Loading";
import { ButtonFlagsType, ButtonProps } from "../types";
import { SizeType } from "components/src/shared/types";
import { IconProps } from "src/components/Icon/types";
import cx from "components/src/utils/cx";
import {
  iconOnlyFocusedDestructive,
  statusIcons,
} from "../styles/Button.classes";

const pillVariantIconSizes = {
  xs: "sm",
  sm: "md",
  md: "lg",
  lg: "xl",
};
const pillVariantAvatarSizes = {
  xs: "md",
  sm: "lg",
  md: "xl",
  lg: "2xl",
};
const smallAvatar: SizeType[] = ["2xs", "xs", "sm"];
const largeAvatar: SizeType[] = ["md", "lg", "xl"];

export const Spacer = ({ fluid }: Partial<ButtonProps>) =>
  fluid ? (
    <span className={"cui__spacer cui-fluid-block cui-flex-grow"} />
  ) : null;

export const RightIcon = ({
  size,
  rightIcon,
  fluid,
  FLAGS,
  team,
  variant,
}: Partial<ButtonProps> & { FLAGS: ButtonFlagsType }) => {
  if (FLAGS.iconOnly || !FLAGS.hasRightIcon) return null;
  let _icon: IconProps | string | null = null;
  if (rightIcon && typeof rightIcon === "string") _icon = { name: rightIcon }
  if (rightIcon && typeof rightIcon === "object" && "name" in rightIcon) _icon = rightIcon;
  const hasColor = !!_icon && "color" in _icon && !!_icon.color;
  return (
    <>
      <Spacer fluid={fluid} />
      {_icon ? (
        <Icon
          {..._icon}
          size={
            variant === "pill" && size
              ? (pillVariantIconSizes[size] as SizeType)
              : size
          }
          className={cx(
            {
              "cui__color_icon": hasColor,
              "cui-translate-y-2": Boolean(FLAGS.canShowDescription) && !!fluid,
              ["className" in _icon ? `${_icon?.className}` : ""]: true
            }
          )}
        />
      ) : null}
    </>
  );
};

export const LeftIcon = ({
  status,
  fluid,
  size,
  leftIcon,
  variant,
  team,
  sport,
  FLAGS,
}: Partial<ButtonProps> & { FLAGS: ButtonFlagsType }) => {
  
  let _icon: IconProps | string | null = null;
  if (leftIcon && typeof leftIcon === "object" && "name" in leftIcon) _icon = {...leftIcon};
  if (leftIcon && typeof leftIcon === "string") _icon = { name: leftIcon }
  if (status && status in statusIcons && !leftIcon && leftIcon !== null) _icon = { name: statusIcons[status] };
 
  const hasColor = _icon && ("color" in _icon && _icon.color) || _icon && ("name" in _icon && _icon.name && /color|brand/.test(_icon.name));
  
  const _size =
    variant === "pill" && size
      ? (pillVariantIconSizes[size] as SizeType)
      : size;
  const hasXsLoader = size === "sm" || size === "xs";
  
  if (FLAGS.hasStatus && status === "pending" && !leftIcon && leftIcon !== null)
    return <Loading variant="subtle" size={hasXsLoader ? "xs" : "sm"} />;
  if (!FLAGS.hasIcon && !FLAGS.hasRightIcon && !team && !FLAGS.hasStatus)
    return null;

  return (
    <>
      {fluid && FLAGS.hasRightIcon ? <Spacer fluid={fluid} /> : null}
      {_icon ? <Icon {..._icon} size={_size} className={[hasColor ? "cui__color_icon" : "", "className" in _icon ? `${_icon?.className}` : ""].join(" ")} /> : null}
      {team ? (
        <Avatar
          team={team}
          of="athlete"
          sport={sport}
          size={size ? (pillVariantAvatarSizes[size] as SizeType) : size}
          className={cx({
            "-cui-ml-2xs cui-mr-2xs": smallAvatar.includes(size),
            "-cui-ml-md cui-mr-xs": largeAvatar.includes(size),
          })}
        />
      ) : null}
    </>
  );
};
