import React, { useRef, useState, useCallback, forwardRef } from "react";
import { MarketFixtureProps } from "./types";
import { Cardstack } from "../../../Cardstack/Cardstack";
import { Button } from "../../../Button/Button";
import { MarketTemplate } from "../../../MarketTemplate/MarketTemplate";
0;
import { randomIdString } from "../../../../shared/utils/randomIdString";
import { MarketFixtureHeader } from "./components/MarketFixtureHeader";

export const _MarketFixture = forwardRef<HTMLDivElement, MarketFixtureProps>(
  (
    {
      marketTemplates,
      id,
      title,
      description,
      leadingIcon,
      trailingDetails,
      collapsible = false,
      headerBg,
      mode = "light",
      children,
      initiallyOpen = true,
      onHeaderClick,
      restrictTo,
      animation = true,
      actionText,
      hideActionText,
      hideHeader,
      ...rest
    }: MarketFixtureProps,
    ref: any
  ) => {
    if (!marketTemplates && !children && !title) return null;
    const [isOpen, setIsOpen] = useState(initiallyOpen);
    const randStr = useRef(randomIdString());
    const _id = id ? id : `cui__market-fixture-id-${randStr.current}`;
    const _trailingDetails =
      Array.isArray(trailingDetails) && !!trailingDetails?.length
        ? trailingDetails
        : null;

    const handleHeaderClick = useCallback((e) => {
      onHeaderClick && onHeaderClick({ e, id: _id });
    }, []);

    const handleOnToggle = useCallback(({ isOpen }) => {
      setIsOpen(isOpen);
    }, []);
    return (
      <Cardstack
        restrictTo={restrictTo}
        noPad
        ref={ref}
        {...rest}
        id={_id}
        collapsible={collapsible}
        initiallyOpen={collapsible ? initiallyOpen : true}
        onToggle={handleOnToggle}
        animation={animation}
      >
        {hideHeader ? null : (
          <Cardstack.Header noPad>
            {
              <MarketFixtureHeader
                id={_id}
                title={title}
                description={description}
                leadingIcon={leadingIcon}
                trailingDetails={_trailingDetails}
                collapsible={collapsible}
                headerBg={headerBg}
                actionText={actionText}
                hideActionText={hideActionText}
                isOpen={isOpen}
                onClick={handleHeaderClick}
              />
            }
          </Cardstack.Header>
        )}
        {children}
        {!!marketTemplates
          ? marketTemplates.map((mt, i) => {
              const keyStr = `cui__market-fixture-key-$${
                mt?.id || randStr.current
              }-${i}`;
              return <MarketTemplate {...mt} key={keyStr} />;
            })
          : null}
      </Cardstack>
    );
  }
);

export const MarketFixture = Object.assign({}, _MarketFixture, {
  Header: Cardstack.Header,
  SubHeader: Cardstack.SubHeader,
  PreFooter: Cardstack.PreFooter,
  Footer: Cardstack.Footer,
  displayName: "MarketFixture",
});
