/**
 * Various Support checks for the browser or otherwise
 */


const hasNavigator = typeof navigator !== "undefined";

export const isMobile = hasNavigator && /iPhone|iPad|iPod|Android/i.test(navigator?.userAgent);

export const isiOS = hasNavigator && /iPhone|iPad|iPod/i.test(navigator?.userAgent);
// export const isSafariDesktop = // Detect Safari 
export const isSafari = hasNavigator && navigator?.userAgent.indexOf("Safari") > -1; 

// Discard Safari since it also matches Chrome 
// if ((chromeAgent) && (safariAgent)) safariAgent = false; 

/**
 * Check for Container Query Support
 */

export function checkHasContainerQueries() {
  if (typeof window === "undefined") return false;
  return window && window?.CSS && "supports" in window?.CSS
    ? CSS?.supports("container-type", "inline-size")
    : false;
}
