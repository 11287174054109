import React, { useCallback, useMemo, useState } from "react";
import type {
  BoxScoreProps,
  BoxScoreDateType,
  BoxScoreVariantType,
  GameStateType,
  BoxScoreSyncMapperType
} from "../types";
import cx from "components/src/utils/cx";
import { StartsIn } from "./StartsIn";
import { Countdown } from "../../Countdown/Countdown";
import { DateDetails } from "../../DateDetails/DateDetails";
import { LiveStreamIcon } from "./LiveStreamIcon";
import { EventDetails } from "components/src/components/MarketTemplate/components/MarketFooter/components/EventDetails";
import type { MarketTemplateEventDetailsType } from "components/src/components/MarketTemplate/types";
import pick from "lodash-es/pick";

const boxScoreSyncMapper = (data) => {
  return pick(data, ["gameState", "gameTime"]);
}

const LiveDetails = ({
  eventDetails = { gameState: "---", gameTime: "---" },
  syncMapper
}: {
  eventDetails?: MarketTemplateEventDetailsType;
  syncMapper?: BoxScoreSyncMapperType;
}) => {

  // Manipulate passed in `eventDetails` for further customization.  Different from the 'syncMapper'
  const _eventDetails = useMemo(()=>{
    return pick(eventDetails, ["gameState", "gameTime", "competitor1", "competitor2", "sync"]);
  },[eventDetails]);
  
  return (
    <div className="cui-flex cui-flex-col cui-ring-1 cui-ring-highlight-accent cui-rounded-sm">
      <div className="cui-flex cui-justify-center cui-bg-highlight-accent cui-text-highlight-accent-minimal display-xs-bold cui-p-2xs">
        Live
      </div>
      <div className="cui-text-highlight-accent cui-px-2xs cui-py-2px display-xs">
        <EventDetails
          {..._eventDetails}
          noMinHeight={true}
          noPad={true}
          textSize={"display-xs"}
          dividerColor="cui-bg-highlight-accent-subtle"
          syncMapper={syncMapper ? syncMapper : boxScoreSyncMapper}
        />
      </div>
    </div>
  );
};

export const BoxScoreDetails = ({
  date,
  hasLiveStream,
  variant,
  showCountdown,
  gameState,
  eventDetails,
  syncMapper
}: {
  date: BoxScoreDateType;
  hasLiveStream?: boolean;
  variant: BoxScoreVariantType;
  showCountdown?: boolean;
  gameState: GameStateType;
  eventDetails?: MarketTemplateEventDetailsType;
  syncMapper?: BoxScoreSyncMapperType;
}) => {
  const [showStartsIn, setShowStartsIn] = useState(false);
  const handleCountdownStop = useCallback(() => {
    setShowStartsIn(true);
  }, []);

  const _Details = useMemo(() => {
    return gameState === "live" ? (
      <LiveDetails eventDetails={eventDetails} syncMapper={syncMapper} />
    ) : (
      <DateDetails date={date} variant="outlined" />
    );
  }, [variant, date, gameState]);

  const isPreGame = gameState === "pregame";
  return (
    <div
      className={cx(
        {
          "cui-min-h-[50px]": variant === "vertical",
          "cui-flex-col": variant === "vertical" && !isPreGame,
          "cui-flex-col cui-min-h-[58px]": variant === "horizontal",
        },
        "cui-flex cui-items-center cui-justify-center cui-pointer-events-none cui-gap-2px"
        // cui-gap-2xs
      )}
    >
      {variant === "vertical" ? (
        <>
          <div className="cui-flex cui-flex-col cui-pr-xs cui-items-end">
            {showCountdown ? (
              <>
                <div className="cui-flex cui-flex-col cui-items-end">
                  <StartsIn date={date} hide={showStartsIn} />
                  <Countdown
                    from={Date.now()}
                    stopAt={date}
                    onStop={handleCountdownStop}
                    className="-cui-mr-2px"
                  />
                </div>
                {hasLiveStream && isPreGame ? <LiveStreamIcon /> : null}
              </>
            ) : null}
          </div>
          {_Details}
        </>
      ) : (
        <>
          {showCountdown ? (
            <div className="cui-flex cui-flex-wrap sbk-phone:cui-flex-nowrap cui-text-center cui-justify-center cui-items-center">
              <StartsIn
                date={date}
                className="cui-pr-2xs"
                hide={showStartsIn}
              />
              <Countdown
                from={Date.now()}
                stopAt={date}
                onStop={handleCountdownStop}
              />
            </div>
          ) : null}
          <div className="cui-text-center">{_Details}</div>
        </>
      )}
    </div>
  );
};
