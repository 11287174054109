import React, { memo } from "react";
import { Icon } from "../../Icon/Icon";
import { Score } from "../../Score/Score";
import cx from "components/src/utils/cx";
import { useSync } from "components/src/shared/hooks/useSync";
import type { CompetitorVariantType } from "../types";
import type { SizeType } from "components/src/shared/types";
import type { SyncType } from "components/src/shared/hooks/useSync";

export type ScoreWithPossesionProps = {
  score: number | string;
  inverse: boolean; 
  showAnimation: boolean;
  compact: boolean;
  sport: string; 
  possession: boolean;
  variant: CompetitorVariantType; 
  hasScore: boolean;
  size: SizeType; 
  forcedReadableToken?: string;
  sync?: SyncType;
}

export const ScoreWithPossesion = memo<ScoreWithPossesionProps>((props) => {
  /**
   * Sync
   */
  const propsWithSync = useSync<ScoreWithPossesionProps>(props);
  const {
    score, inverse, showAnimation, compact, sport, possession, variant, hasScore, size, forcedReadableToken
  } = propsWithSync;
  // console.log({ score, possession}, "from socrewithpossession")
  const isVerticalVariant = variant === "vertical";
  const _hasScore = hasScore || !!score;
  return (
    <>
      {_hasScore ? (
        <div
          className={cx(
            {
              "cui-mr-2 cui-ml-1 md:cui-mr-4 md:cui-ml-2px": inverse && !isVerticalVariant,
              "-cui-order-2 cui-flex-grow": !compact && inverse && !isVerticalVariant,
              "cui-ml-2 cui-mr-1 md:cui-ml-4 md:cui-mr-2px": !inverse && !isVerticalVariant,
            },
            "cui-flex cui-items-center"
          )}
        >
          <Score score={score} showAnimation={showAnimation} sport={sport} size={size} />
        </div>
      ) : null}
      <div
        className={cx(
          {
            "cui-opacity-0": !possession,
            "cui-opacity-100": possession,
            // "-cui-ml-1": !inverse,
            "cui-order-first": inverse,
            "cui-ml-1": !inverse && !hasScore,
            "cui-order-last": inverse && compact && !isVerticalVariant,
          },
          `cui-flex cui-items-center cui-transition-opacity cui-duration-200 cui-select-none`
        )}
      >
        <Icon
          name="icon_ui_triangle-sm-right"
          className={cx({
            "cui-rotate-180": !inverse
          })}
          size="md"
          color={forcedReadableToken ? forcedReadableToken.replace("cui-text-", "") : "highlight-accent"} />
      </div>
    </>
  );
});
