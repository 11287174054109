import { useMemo }  from "react";
import cx from "components/src/utils/cx";

export const CompetitorWrapper = ({ variant, showComputedIcon, className, inline, compact, ariaLabel, tabbable, rest, children }) => {
    const competitorWrapperAttrs = useMemo(() => {
        const [isHorizontal, isVertical] = [variant === "horizontal", variant === "vertical"];
        const variantClasses = cx({
          "cui-inline-flex": isHorizontal,
          "cui-w-full": !inline && isHorizontal,
          "cui-w-auto": inline || isVertical,
          "cui-inline-grid cui-grid-cols-[auto,1fr,auto]":
            isVertical,
          "cui-gap-2xs": isVertical && showComputedIcon,
        });
        const {flipIcon, animation, ..._rest} = rest; // prevent flipIcon from being spread on the div element
        return {
          className:
            `cui__competitor_wrapper cui-relative ${variantClasses} ${className}`.trim(),
          "aria-label": ariaLabel,
          tabIndex: tabbable ? 0 : -1,
          ..._rest,
        };
      }, [variant, ariaLabel, tabbable, rest, showComputedIcon]);

    return <div {...competitorWrapperAttrs}>
        {children}
    </div>
}