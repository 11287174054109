import React, { useLayoutEffect, useRef, useState } from "react";
let firstRender = true;

export const useCssFilter = (
  el,
  activeFilter,
  showLimit,
  groupBy,
  hasFilters,
  hasBeenToggled,
  type,
  finished,
  setFinished,
  disabled = false,
  mountHiddenMarkets: boolean | undefined = undefined
) => {
  const [elements, setElements] = useState({filteredElements: [], unfilteredElements: []});
  const displayType = useRef<string>("");
  const allElements = useRef<HTMLElement[] | HTMLElement[][] | null>();
  let _activeFilter = activeFilter.toLowerCase();
  let _showLimit =
    typeof showLimit === "string" ? showLimit.toLowerCase() : showLimit;
  if (_activeFilter === "list view") _activeFilter = "all";
  if (_showLimit === "list view") _showLimit = "all";
  
  const isListView = type === "slider" && _activeFilter === "all";

  const filterType = isListView
    ? `[data-filter-group-set]`
    : `[data-filter-group]`;

  let filteredElements, unfilteredElements;
  
  useLayoutEffect(() => {
    if (!el || disabled) return;
    if (window && "document" in window) {
      // Get all the dom nodes (elements)
      // If Slider List View, else NColGrouped template
      if (isListView) {
        const elementColumns = Array.from(
          el.querySelectorAll(`${filterType}`) as HTMLElement[]
        );
        allElements.current = elementColumns.map((element) =>
          Array.from(element.querySelectorAll(`[data-filter-group]`))
        ) as HTMLElement[][];
      } else {
        allElements.current = Array.from(
          el.querySelectorAll(`${filterType}`) as HTMLElement[]
        );
        filteredElements = allElements.current.filter((el) => matchToFilter(_activeFilter, el, true));
        unfilteredElements = allElements.current.filter((el) => matchToFilter(_activeFilter, el, false));
        setElements({filteredElements, unfilteredElements})
      }

      // Process dom nodes (elements)
      if (allElements.current) {
        // If Slider List View, else NColGrouped template
        if (isListView) { 
          allElements.current.forEach((group) =>
            group.forEach(
              filterElements(displayType, _showLimit, _activeFilter)
            )
          );
        } else {
          if (_activeFilter === "all") {
            (allElements.current as HTMLElement[]).forEach(
              filterElements(displayType, _showLimit, _activeFilter)
            );
          } else {
            filteredElements.forEach(
              filterElements(displayType, _showLimit, _activeFilter)
            );
            unfilteredElements.forEach(hideElements(displayType));
          }
        }
      }
      if (setFinished && typeof setFinished === "function" && finished.firstTime) {
        setFinished({ firstTime: false, value: true})
      };
    }
  }, [activeFilter, el, showLimit, hasFilters, groupBy, hasBeenToggled, mountHiddenMarkets]);
  return [elements.filteredElements, elements.unfilteredElements || allElements.current]
};

function filterElements(
  displayType: React.MutableRefObject<string>,
  _showLimit: "all" | number,
  _activeFilter: string
): (value: HTMLElement, index: number, array: HTMLElement[]) => void {
  return (_el, i) => {
    if (firstRender) {
      displayType.current = window.getComputedStyle(_el).display;
      firstRender = false;
    }
    const $el = displayElement(_el, displayType.current);

    const canShowLimit =
      _showLimit !== "all" && typeof _showLimit === "number" && i < _showLimit;
    const canShowElement =
      _showLimit === "all" || canShowLimit

    if (canShowElement) {
      $el.show();
    } else {
      $el.hide();
    }
  };
}

function hideElements(
  displayType: React.MutableRefObject<string>
): (value: HTMLElement, index: number, array: HTMLElement[]) => void {
  return (_el, i) => {
    if (firstRender) {
      displayType.current = window.getComputedStyle(_el).display;
      firstRender = false;
    }
    const $el = displayElement(_el, displayType.current);
    $el.hide();
  };
}

function displayElement(el, displayType) {
  return {
    show() {
      el.style.display = displayType;
    },
    hide() {
      el.style.display = "none";
    },
  };
}
// match: boolean, if true match to filterGroup, if false, don't match to the filterGroup. 
function matchToFilter(activeFilter, el, match) {
  if (!el) return false;
  return match ? activeFilter.toLowerCase() === el?.dataset?.filterGroup?.toLowerCase() : activeFilter.toLowerCase() !== el?.dataset?.filterGroup?.toLowerCase();
}