export const nbsp = "\u00A0";

export const topFourLeagues = ["mlb", "nhl", "nba", "nfl"];

export const lineClampMap = {
  1: "cui-line-clamp-1",
  2: "cui-line-clamp-2",
  3: "cui-line-clamp-3",
  4: "cui-line-clamp-4",
};

export const readableColorMap = {
    "#000": "cui-text-fg-global-black",
    "#fff": "cui-text-fg-global-white"
}

export const textAlignMap = {
  "left": "",
  "center": "cui-text-center",
  "right": "cui-text-right"
}