import React from "react";
import { Body } from "../../Typography/Typography";
import { Icon } from "../../Icon/Icon";

export const LiveStreamIcon = ({className}: { className?: string}) => (
    <Body
      size="xs-bold"
      className={`cui-m-0 cui-flex cui-items-center cui-whitespace-nowrap ${className}`}
      color="fg-moderate"
    >
      <Icon
        name="ui_watch"
        size="sm"
        color="highlight-accent"
        className="cui-mr-2xs"
      />
      Livestream Available
    </Body>
  );